var AJAX = new (function () {
    this.initErtekeles = function (id, max, mentett) {
        var desc = [
            'Elég könnyű',
            'Könnyű',
            'Közepes',
            'Nehéz',
            'Nagyon nehéz',
        ];

        var tmp = '';

        var i;
        for (i = 1; i <= 5; i++) {
            tmp +=
                '<a desc="' +
                desc[i - 1] +
                '" href="javascript:AJAX.sendErtekeles(\'' +
                id +
                "', " +
                id.substr(10) +
                ', ' +
                i +
                ');" onmouseout="javascript:AJAX.Ertekeles(\'' +
                id +
                "', " +
                mentett +
                ', ' +
                mentett +
                ');" onmouseover="javascript:AJAX.Ertekeles(\'' +
                id +
                "', " +
                i +
                ', ' +
                mentett +
                ');"></a>';
        }

        document.getElementById(id).innerHTML = tmp;

        this.Ertekeles(id, max, mentett);
    };

    this.Ertekeles = function (id, max) {
        var box = document.getElementById(id);

        var linkek = box.getElementsByTagName('a');

        var img = 'csillag1.png';

        var i;
        for (i = 0; i < linkek.length; i++) {
            if (i + 1 > max) {
                img = 'csillag0.png';
            }

            linkek.item(i).style.backgroundImage = 'url(img/' + img + ')';
        }
    };

    this.sendErtekeles = function (id, fejtesID, pont) {
        var http = new XMLHttpRequest();

        http.open(
            'GET',
            'index.php?oldal=ertekeles&fejtesID=' + fejtesID + '&pont=' + pont,
            true,
        );

        http.onreadystatechange = function () {
            if (http.readyState === 4 && http.status === 200) {
                if (http.responseText === '200') {
                    AJAX.initErtekeles(id, pont, pont);
                } else {
                    document.getElementById(id).innerHTML = http.responseText;
                }
            }
        };

        http.send();
    };
})();
